<template>
  <div class="nav-con">
    <div class="nav-info">
      <img class="bgcv" :src="require('@/assets/images/navt.png')" alt="" />
      <!-- <img class="topdata" :src="require('@/assets/images/title.png')" alt="" /> -->
      <span class="topdata">{{ userInfo.regionName }}农产品质量安全监管</span>
      <div class="nav-left">
        <div class="date-con">
          <h1>{{ yearMonth }}</h1>
          <h1>{{ cycle }}</h1>
        </div>
      </div>
      <div class="title-con">
        <span>{{ userInfo.regionName }}农产品质量安全监管</span>
      </div>
      <div class="nan-ri">
        <span class="time">{{ time }}</span>
        <div class="exit-con" @click="handleQuit">
          <img class="icon" :src="require(`@/assets/images/out.png`)" alt="" />
        </div>
      </div>
      <div class="module-title">
        <span>{{ titleName }}</span>
      </div>
      <div class="module-list">
        <div
          v-for="item in list"
          :key="item.id"
          @click="handleNavitem(item)"
          class="module-row"
        >
          <img v-if="item.check" :src="item.select" alt="" />
          <img v-else :src="item.selectNot" alt="" />
          <span :class="item.check ? 'xz' : 'not'">{{ item.name }}</span>
          <h1 v-if="item.check" class="triangle"></h1>
        </div>
      </div>
    </div>

    <a-modal
      title="提示"
      :visible="quitPopShow"
      @ok="handleQuitOk"
      @cancel="handleQuitCancel"
    >
      <p>确定退出？</p>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MutationType from "@/store/mutation-type.js";
import { Progress, Tooltip } from "ant-design-vue";
import "ant-design-vue/lib/tooltip/style/index.css"; // 或者 ant-design-vue/lib/button/style/css 加载 css 文件
import "ant-design-vue/lib/progress/style/index.css"; // 或者 ant-design-vue/lib/button/style/css 加载 css 文件
import loginApi from "@/request/api/user.js";
import moment from "moment";

export default {
  name: "NavBar",
  props: {
    list: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  watch: {
    list(newValue, oldValue) {
      console.log(`list watched`);
      this.convertList(newValue);
    },
  },
  components: {
    "a-tooltip": Tooltip,
    "a-progress": Progress,
  },
  data() {
    return {
      leftList: [],
      rightList: [],
      quitPopShow: false,
      isFullShow: true,
      yearMonth: [], //时间
      cycle: moment().format("dddd"),
      time: "",
      titleName: "数据中心",
    };
  },
  computed: {
    ...mapGetters(["region", "userInfo", "token"]),
  },
  mounted() {
    // this.convertList(this.list);
    // this.getInfo();
    this.yearMonth = moment().format("YYYY/MM/DD");
    setInterval(() => {
      this.time = moment().format("HH:mm:ss");
    }, 100);
    this.$eventBus.$on("changeNav", (params) => {
      // console.log("event bus receive is ", params);
      let { index } = params;
      this.resetListAllItemActive(this.list);
      this.list[index].check = true;
    });
  },
  methods: {
    async getInfo() {
      try {
        let info = await loginApi.info();
        this.$store.commit("setUserInfo", info);
        if (info.meshUser) {
          let regionName = info.meshUser.regionName;
          if (
            regionName.indexOf("酉阳") != -1 ||
            regionName.indexOf("石柱") != -1 ||
            regionName.indexOf("秀山") != -1 ||
            regionName.indexOf("彭水") != -1
          ) {
            let name1 = regionName.substring(0, 2);
            let name2 = regionName.substr(-1);
            console.log(name1, name2);
            let region = `${name1}${name2}`;
            this.$store.commit("setRegion", region);
          } else {
            this.$store.commit("setRegion", regionName);
          }
        }
      } catch (error) {
        console.log(error.msg);
      }
    },
    handleQuitCancel() {
      this.quitPopShow = false;
    },
    /** 确定退出 */
    handleQuitOk() {
      this.quitPopShow = false;
      if (document.fullscreenElement) {
        document.exitFullscreen();
        this.isFullShow = true;
      }
      // this.$store.commit("setRegion", null);
      // this.$store.commit("setUserInfo", null);
      this.$store.commit(MutationType.SET_TOKEN_MUTATION, null);
      this.$router.push({
        name: "Login",
      });
    },
    handleQuit() {
      this.quitPopShow = true;
    },
    /** 首字母大写s */
    titleCase(str) {
      let newStr = str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase();
      return newStr;
    },
    convertList(list) {
      if (list.length == 0) {
        return;
      }
      let relist = [];
      let spitnum = Math.ceil(list.length / 2);
      for (let i = 0, len = list.length; i < len; i += spitnum) {
        relist.push(list.slice(i, i + spitnum));
      }
      if (relist.length > 1) {
        let leftlist = JSON.parse(JSON.stringify(relist[0]));
        let rightlist = JSON.parse(JSON.stringify(relist[1]));
        leftlist.forEach((element) => {
          element.router.name = this.titleCase(element.router.name);
          element.active = false;
        });
        rightlist.forEach((element) => {
          element.router.name = this.titleCase(element.router.name);
          element.active = false;
        });
        if (leftlist.length > rightlist.length) {
          let count = leftlist.length - rightlist.length;
          for (let index = 0; index < count; index++) {
            let citem = {
              id: `nvbarfillitem-${index}`,
              fillempty: true,
              router: {
                name: "",
              },
            };
            rightlist.push(citem);
          }
        }
        this.leftList = leftlist;
        this.rightList = rightlist;
        return;
      }
      this.$toast.fail("菜单栏配置有误,请联系管理员");
    },
    handleFullscreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen();
        return;
      }
      let element = document.getElementsByTagName("body")[0];
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else {
        element.msRequestFullscreen();
      }
    },
    handleNavitem(item) {
      this.resetListAllItemActive(this.list);
      item.check = true;
      this.titleName = item.name;
      this.$emit("change", item);
    },
    resetListAllItemActive(list) {
      for (let index = 0; index < list.length; index++) {
        let element = list[index];
        element.check = false;
      }
    },
    screenfullTap() {
      if (this.isFullShow) {
        const html = document.querySelector("html");
        html
          .requestFullscreen()
          .then(() => {
            console.log("进入全屏成功");
            this.isFullShow = false;
          })
          .catch(() => {
            console.log("进入全屏失败");
          });
      } else {
        if (document.fullscreenElement) {
          document.exitFullscreen();
          this.isFullShow = true;
          return;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-con {
  position: relative;
  height: 815px;
  // z-index: 9999;
  .nav-info {
    position: relative;
    width: 100%;
    height: 100%;
    .bgcv {
      position: absolute;
      left: 50%;
      // top: -125px;
      transform: translateX(-50%);
      width: 100%;
      height: 692px;
    }
    .topdata {
      width: 271px;
      position: absolute;
      left: 50%;
      top: 20px;
      transform: translateX(-50%);
      font-size: 22px;
      font-family: "douyu";
      font-weight: 700;
      font-style: italic;
      text-align: left;
      color: #ffffff;
      white-space: nowrap;
      // background-image: -webkit-linear-gradient(
      //   bottom,
      //   #88dfff 0%,
      //   #d2f6ff 50%,
      //   #eef6ff 100%
      // );
      // text-shadow: -11px 0px 29px 11px rgba(46, 126, 217, 0.3);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
    .nav-left {
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: absolute;
      left: 25px;
      top: 50px;
      .date-con {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        h1 {
          &:nth-child(1) {
            font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #cfebff;
            margin-right: 11px;
          }
          &:nth-child(2) {
            font-size: 15px;
            font-family: DIN-Medium;
            font-weight: 400;
            color: #cfebff;
          }
        }
      }
      .line {
        width: 1px;
        height: 36px;
        background: #3d9ec3;
        margin-left: 7px;
        margin-right: 7px;
        margin-top: 7px;
      }
    }
    .navicon {
      position: relative;
      img {
        width: 202px;
        height: 43px;
      }
      .navicon-con {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -60%);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        img {
          width: 15px;
          height: 14px;
          margin-right: 8px;
        }
      }
    }
  }
  .nan-ri {
    position: absolute;
    top: 50px;
    right: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .time {
      font-size: 24px;
      font-family: "pmzdbz";
      font-weight: 400;
      color: #cfebff;
      // text-shadow: 0px 0px 6px rgba(113, 247, 255, 0.69);
      margin-right: 26px;
    }
    .exit-con {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      .icon {
        width: 16px;
        height: 16px;
        margin-right: 2px;
      }
      span {
        font-size: 18px;
        font-family: Alibaba PuHuiTi;
        font-weight: 400;
        color: #00d5fe;
      }
    }
    .exit-con:nth-child(1) {
      margin-right: 50px;
    }
  }
  .module-title {
    position: absolute;
    top: 555px;
    left: 50%;
    transform: translateX(-50%);
    span {
      font-size: 22px;
      font-family: Source Han Sans CN, Source Han Sans CN-Bold;
      font-weight: 700;
      text-align: left;
      color: #ffffff;
      line-height: 7px;
      letter-spacing: 1.65px;
    }
  }

  .title-con {
    position: absolute;
    top: 169px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    span {
      background-image: -webkit-linear-gradient(
        bottom,
        #88dfff 0%,
        #d2f6ff 72%,
        #eef6ff 100%
      );
      font-size: 36px;
      font-family: "douyu";
      font-weight: 700;
      text-align: left;
      color: #d0f0ff;
      // letter-spacing: 1.8px;
      text-shadow: -11px 0px 29px 11px rgba(46, 126, 217, 0.3);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .module-list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    position: absolute;
    top: 625px;
    width: 100%;
    .module-row {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .xz {
        font-size: 20px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        text-align: left;
        color: #ffffff;
        line-height: 30px;
        letter-spacing: 0.8px;
      }
      .not {
        font-size: 16px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        font-weight: 400;
        text-align: left;
        color: #d6edff;
        line-height: 30px;
        letter-spacing: 0.64px;
      }
    }
    .triangle {
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid #008aff;
    }
  }
}
</style>
